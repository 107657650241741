import EditorContent from "./EditorContent";
import { useState, useCallback, useLayoutEffect } from "react";
import { last, cloneDeep } from "lodash-es";
import { useSelector, useDispatch } from "react-redux";
import { useRequest } from "ahooks";
import { feedback } from "@/utils/index";
import { data as dataService } from "@/services";
import { OUTLINE_EMPTY, formatOutline, parseOutline, validateOutline } from "./template";
import "./style.less";

function OutlineBubble({ questionType, text, status, setComposerText }) {
  const [outline, setOutline] = useState(OUTLINE_EMPTY);
  const [error, setError] = useState([]);
  const [dataOptions, setDataOptions] = useState([]);
  const [dataAllowed, setDataAllowed] = useState(false);
  const { sessionId, paths } = useSelector((state) => state.document);
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    if (!text) {
      return;
    }
    parseCurrentOutline();
  }, []);

  useLayoutEffect(() => {
    if (!!sessionId || last(paths)) {
      setDataAllowed(true);
      getSessionDataNames();
    }
  }, []);

  const { loading, run: parseCurrentOutline } = useRequest(
    () =>
      dataService.parseOutline({
        outline: text,
      }),
    {
      manual: true,
      ready: !!text,
      onSuccess: (res) => {
        if (!res || !res.data) {
          setOutline(OUTLINE_EMPTY);
          return;
        }
        setOutline(parseOutline(res.data));
      },
      onError: (err) => {
        feedback(err.data);
      },
    },
    { manual: true }
  );

  const { run: getSessionDataNames } = useRequest(
    () => dataService.getSessionDataNames(sessionId || last(paths), {}),
    {
      manual: true,
      ready: !!sessionId || last(paths),
      onSuccess: (res) => {
        if (!res || !res.data || !res.data.length) {
          setDataOptions([]);
          return;
        }
        setDataOptions(res.data.map((v) => ({ labe: v, value: v })));
      },
      onError: (err) => {
        feedback(err.data);
      },
    },
    { manual: true }
  );

  // 更新文本值或选项数据
  const updateText = useCallback(
    ({ type, text, level1Index, level2Index, level3Index }) => {
      const newOutline = cloneDeep(outline);
      if (["title", "lang"].includes(type)) {
        newOutline[type] = text || "";
        setOutline(newOutline);
      }
      if (!level1Index && level1Index !== 0) {
        return;
      }
      newOutline.nodes = newOutline.nodes || [];
      // 只有level1
      if (!level2Index && level2Index !== 0) {
        newOutline.nodes[level1Index][type] = text;
        setOutline(newOutline);
        return;
      }
      // 只有level1、level2
      if ((!!level2Index || level2Index === 0) && !level3Index && level3Index !== 0) {
        newOutline.nodes[level1Index].nodes = newOutline.nodes[level1Index].nodes || [];
        newOutline.nodes[level1Index].nodes[level2Index][type] = text;
        setOutline(newOutline);
        return;
      }
      // 只有level1、level2、level3
      newOutline.nodes[level1Index].nodes[level2Index].nodes =
        newOutline.nodes[level1Index].nodes[level2Index].nodes || [];
      newOutline.nodes[level1Index].nodes[level2Index].nodes[level3Index][type] = text;
      setOutline(newOutline);
    },
    [outline, setOutline]
  );

  // 添加一级或二级或三级大纲
  const addNode = useCallback(
    ({ level1Index, level2Index, level3Index }) => {
      const newOutline = cloneDeep(outline);
      newOutline.nodes = newOutline.nodes || [];
      // 只有level1
      if (!level2Index && level2Index !== 0) {
        const nodes = [...newOutline.nodes];
        nodes.splice(level1Index + 1, 0, {
          nodeName: "",
          nodeDesc: "",
          nodeExpt: "",
          nodes: [],
        });
        newOutline.nodes = nodes;
        setOutline(newOutline);
        return;
      }
      // 只有level1、level2
      newOutline.nodes[level1Index].nodes = newOutline.nodes[level1Index].nodes || [];
      if ((!!level2Index || level2Index === 0) && !level3Index && level3Index !== 0) {
        const nodes = [...newOutline.nodes[level1Index].nodes];
        nodes.splice(level2Index + 1, 0, {
          nodeName: "",
          nodeDesc: "",
          nodeExpt: "",
          nodes: [],
        });
        newOutline.nodes[level1Index].nodes = nodes;
        setOutline(newOutline);
        return;
      }
      // 只有level1、level2、level3
      newOutline.nodes[level1Index].nodes[level2Index].nodes =
        newOutline.nodes[level1Index].nodes[level2Index].nodes || [];
      const nodes = [...newOutline.nodes[level1Index].nodes[level2Index].nodes];
      nodes.splice(level3Index + 1, 0, {
        nodeName: "",
        nodeDesc: "",
        nodeExpt: "",
      });
      newOutline.nodes[level1Index].nodes[level2Index].nodes = nodes;
      setOutline(newOutline);
    },
    [outline, setOutline]
  );

  // 删除一级或二级大纲
  const deleteNode = useCallback(
    ({ level1Index, level2Index, level3Index }) => {
      const newOutline = cloneDeep(outline);
      newOutline.nodes = newOutline.nodes || [];
      // 只有level1
      if (!level2Index && level2Index !== 0) {
        newOutline.nodes = newOutline.nodes.filter((ignore, index) => index !== level1Index);
        setOutline(newOutline);
        return;
      }
      // 只有level1、level2
      newOutline.nodes[level1Index].nodes = newOutline.nodes[level1Index].nodes || [];
      if ((!!level2Index || level2Index === 0) && !level3Index && level3Index !== 0) {
        newOutline.nodes[level1Index].nodes = newOutline.nodes[level1Index].nodes.filter(
          (ignore, index) => index !== level2Index
        );
        setOutline(newOutline);
        return;
      }
      // 只有level1、level2、level3
      newOutline.nodes[level1Index].nodes[level2Index].nodes =
        newOutline.nodes[level1Index].nodes[level2Index].nodes || [];
      newOutline.nodes[level1Index].nodes[level2Index].nodes = newOutline.nodes[level1Index].nodes[
        level2Index
      ].nodes.filter((ignore, index) => index !== level3Index);
      setOutline(newOutline);
    },
    [outline, setOutline]
  );

  return (
    <div className="outline-bubble">
      <EditorContent
        error={error}
        outline={outline}
        dataAllowed={dataAllowed}
        dataOptions={dataOptions}
        updateText={updateText}
        addNode={addNode}
        deleteNode={deleteNode}
      />
    </div>
  );
}

export default OutlineBubble;
