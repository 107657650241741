import { Fragment, useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Avatar, Button, Dropdown, Image, Space, Tooltip, message } from "antd";
import {
  LoginOutlined,
  LogoutOutlined,
  QuestionCircleOutlined,
  SettingOutlined,
  FileSyncOutlined,
} from "@ant-design/icons";
import ThingIcons from "@/icons/ThingIcons";
import { checkIsInWeixin } from "@/utils/weixin";
import AuthModal from "./AuthModal";
import AuthByWeixin from "./AuthByWeixin";
import { USAGE_RULE, USER_TYPE_DESCRIPTION } from "@/constants/writing";
import useScreenSize from "@/hooks/useScreenSize";

import styles from "./index.module.less";

const CoinsIcon = (props) => (
  <svg
    className="icon"
    viewBox="0 0 1024 1024"
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    {...props}
  >
    <path
      d="M992.008442 211.209977c0-137.173922-247.314194-211.208954-480.007419-211.208954-231.345533 0-477.123748 73.177503-479.977743 208.821585l-0.031722-0.013303 0 608.931463 0.118703 0.045025c5.930048 133.980189 250.034137 206.213183 479.888716 206.213183 229.864812 0 473.978111-72.239133 479.888716-206.226486l0.121773-0.031722L992.009466 211.821913l-0.00921 0C991.999233 211.620322 992.008442 211.415661 992.008442 211.209977L992.008442 211.209977zM92.201751 533.39115c79.673438 64.470208 233.499588 106.25888 419.796203 106.25888 186.287405 0 340.116625-41.785602 419.796203-106.251717l0 104.819091c-20.157045 67.532958-186.913667 137.34686-419.796203 137.34686-232.936771 0-399.703626-69.845625-419.796203-137.394955L92.201751 533.39115 92.201751 533.39115zM931.800296 442.070464c-20.163185 67.528864-186.92083 137.34993-419.799272 137.34993-232.936771 0-399.703626-69.855858-419.799272-137.398025L92.201751 317.712404c87.519111 68.562402 257.220802 104.703458 419.799272 104.703458 162.575401 0 332.277092-36.136963 419.799272-104.703458L931.800296 442.070464 931.800296 442.070464zM511.997953 60.238846c242.402334 0 423.537403 79.70823 423.537403 150.972155 0 71.266994-181.135068 150.972155-423.537403 150.972155-242.405404 0-423.537403-79.70516-423.537403-150.972155C88.461574 139.947076 269.596642 60.238846 511.997953 60.238846L511.997953 60.238846zM511.997953 963.766271c-222.123516 0-392.794276-66.924092-419.796203-132.958932l0-101.276412c79.673438 64.476348 233.499588 106.26502 419.796203 106.26502 186.287405 0 340.116625-41.785602 419.796203-106.25581l0 101.263109C904.796323 896.842179 734.124539 963.766271 511.997953 963.766271L511.997953 963.766271z"
      fill="#08979c"
    ></path>
  </svg>
);

function WindowToolbar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { tokenInfo } = useSelector((state) => state.user);
  const { jwt, avatar } = useSelector((state) => state.token);
  const [open, setOpen] = useState(false);
  const { middle } = useScreenSize();
  const isWeixin = checkIsInWeixin();

  useLayoutEffect(() => {
    dispatch.user.getTokenInfo();
  }, []);

  const items = [
    tokenInfo.openid
      ? {
          label: (
            <Button
              type="link"
              href="/console/"
            >
              ID：{tokenInfo.username}
            </Button>
          ),
          key: "-1",
        }
      : null,
    {
      label: (
        <Button
          type="link"
          href="/console/"
          icon={<SettingOutlined />}
        >
          用户中心
        </Button>
      ),
      key: "0",
    },
    {
      label: (
        <Button
          type="link"
          href="https://www.yuque.com/bi_wk/muac4q"
          target="_blank"
          icon={<QuestionCircleOutlined />}
        >
          帮助
        </Button>
      ),
      key: "2",
    },
    {
      label: (
        <Button
          type="link"
          href="https://www.yuque.com/bi_wk/muac4q/kpkszqvvtkk01ao3"
          target="_blank"
          icon={<FileSyncOutlined />}
        >
          更新日志
        </Button>
      ),
      key: "3",
    },
    {
      type: "divider",
    },
    {
      label: (
        <Button
          type="text"
          icon={tokenInfo.openid ? <LogoutOutlined /> : <LoginOutlined />}
          onClick={() => {
            if (!tokenInfo.openid) {
              setOpen(true);
            } else {
              message.success("您已成功退出");
              navigate("/home", { replace: true });
              dispatch.token.init({ hadLogin: false, hadOpenId: false });
              dispatch.user.resetSession(); //清除tokenInfo
              dispatch.chat.resetSession();
              dispatch.document.resetSession();
              localStorage.removeItem("tabActiveKey");
            }
          }}
        >
          {tokenInfo.openid ? "退出" : "登录"}
        </Button>
      ),
      size: "large",
      key: "4",
    },
  ];

  useEffect(() => {
    if (!jwt) {
      setOpen(true);
    }
  }, [jwt]);

  return (
    <div className={styles.windowToolbar}>
      <Space size={middle ? "small" : "1"}>
        <Tooltip
          title={USER_TYPE_DESCRIPTION[tokenInfo.user_type] ? USER_TYPE_DESCRIPTION[tokenInfo.user_type] : USAGE_RULE}
          placement="bottomRight"
        >
          <Button
            className="left-count"
            size="small"
            type="link"
            href="/console/"
          >
            {tokenInfo.user_type?.includes("点卡") ? (
              <>
                <CoinsIcon style={{ fontSize: 16 }} />
                &nbsp;
                {tokenInfo.left_count || 0}
              </>
            ) : (
              tokenInfo.user_type
            )}
          </Button>
        </Tooltip>
        {!tokenInfo.openid && (
          <>
            <Tooltip
              title={"扫码登录，首次登录赠送100点，后续每天赠送20点（不累计），登录后免费使用GPT4o mini（除文献）。"}
              placement="bottomRight"
            >
              <span
                className="login-gift"
                onClick={() => {
                  setOpen(true);
                }}
              >
                <ThingIcons type="gift" />
              </span>
            </Tooltip>
            <Dropdown
              menu={{ items }}
              overlayClassName={styles.avatarDropdown}
              placement="bottomRight"
              trigger={["hover", "click"]}
              arrow
            >
              <Button
                type="text"
                size="small"
                className="auth-button"
                onClick={() => {
                  setOpen(true);
                }}
              >
                登录/注册
              </Button>
            </Dropdown>
          </>
        )}
        {!!tokenInfo.openid && (
          <Dropdown
            menu={{ items }}
            overlayClassName={styles.avatarDropdown}
            placement="bottomRight"
            arrow
          >
            <Avatar
              className="user-avatar"
              icon={
                <Image
                  src={avatar}
                  preview={false}
                />
              }
            />
          </Dropdown>
        )}
      </Space>
      {isWeixin ? (
        open ? (
          <AuthByWeixin />
        ) : null
      ) : (
        <AuthModal
          open={open}
          setOpen={setOpen}
        />
      )}
    </div>
  );
}

export default WindowToolbar;
