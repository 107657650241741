export const outline = (
  <svg
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    p-id="7570"
    width="18"
    height="18"
  >
    <path
      d="M960 0H256a64 64 0 0 0-64 64v160a64 64 0 0 0 64 64h704a64 64 0 0 0 64-64V64a64 64 0 0 0-64-64z m0 224H256V64h704v160zM960 448H384a64 64 0 0 0-64 64v96a64 64 0 0 0 64 64h576a64 64 0 0 0 64-64v-96a64 64 0 0 0-64-64z m0 160.032H384v-96h576v96zM960 800.032H384a64 64 0 0 0-64 64v96a64 64 0 0 0 64 64h576a64 64 0 0 0 64-64v-96a64 64 0 0 0-64-64z m0 160H384v-96h576v96zM224 592.032a32 32 0 0 0 0-64H64V176h32a32 32 0 1 0 0-64H32a32 32 0 0 0-32 32v768.032a32 32 0 0 0 32 32h192a32 32 0 0 0 0-64H64v-288h160z"
      fill="#60A5FA"
    ></path>
  </svg>
);
