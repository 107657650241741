import { Select } from "antd";
import { cloneDeep } from "lodash-es";
import cls from "classnames";
import styles from "./index.module.less";

const EditorDataSelector = ({
  label,
  value,
  placeholder,
  onChange,
  required = false,
  dataOptions = [],
  invalid = false,
  maxSize = 3,
}) => {
  const options = cloneDeep(dataOptions);
  const filterOption = (input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <div className={styles.editorSelectorWrapper}>
      <div className={cls("editorSelector", { invalid })}>
        {required ? <i className="required">*</i> : null}
        <span className={cls("label")}>{label}</span>
        <Select
          mode="multiple"
          allowClear={true}
          showSearch={true}
          bordered={false}
          maxTagCount={"responsive"}
          placeholder={placeholder}
          value={value}
          defaultValue={value}
          filterOption={filterOption}
          onChange={(data) => onChange({ data })}
          options={options.map((item) => {
            return {
              ...item,
              disabled: value?.length >= maxSize && !value.includes(item.value),
            };
          })}
        />
      </div>
    </div>
  );
};

export default EditorDataSelector;
