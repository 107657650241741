import { useCallback, useEffect, memo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMatch } from "react-router-dom";
import copy from "copy-to-clipboard";
import cls from "classnames";
import axios from "axios";
import { message, Popconfirm, Space, Tooltip } from "antd";
import {
  CopyOutlined,
  EditOutlined,
  DeleteOutlined,
  FileMarkdownOutlined,
  FileTextOutlined,
  LoadingOutlined,
  OrderedListOutlined,
  RedoOutlined,
  CheckOutlined,
  CloudDownloadOutlined,
} from "@ant-design/icons";
import { data as dataService } from "@/services/data";
import { feedback, disableComposer, setBubbleBgColor, setBubbleSelectable } from "@/utils/index";
import styles from "./index.module.less";

// 论文检索结果展示的tools
const scholarTools = ["重新回答", "删除", "批量删除"];

function BubbleToolbar({
  text,
  createdAt,
  setComposerText,
  messageId,
  format,
  formatHandler,
  position,
  status,
  isLast,
  // 判断是否是论文检索结果
  isScholar,
  // 判断是否是音乐
  isMusic,
  isImage,
}) {
  const dispatch = useDispatch();
  const match = useMatch("/chat");

  const { checking } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [reanswering, setReanswering] = useState(false);
  const [popOpen, setPopOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  const [writed, setWrited] = useState(false);
  const [audio] = useState(new Audio());

  useEffect(() => {
    audio.addEventListener("ended", () => setLoading(false));
    return () => {
      audio.pause();
      audio.removeEventListener("ended", () => setLoading(false));
    };
  }, []);

  const copyHandler = useCallback(
    (text) => {
      if (copied) return;
      if (copy(text)) {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 3000);
      } else {
        message.error("无法复制内容，请确认浏览器支持");
      }
    },
    [copied]
  );

  const writeHandler = useCallback(() => {
    if (writed) return;
    const comIpt = document.querySelector(".Composer-input");
    comIpt.focus();
    setComposerText(text);
    setWrited(true);
    setTimeout(() => {
      setWrited(false);
    }, 3000);
  }, [text, setComposerText, writed]);

  const reanswerHandler = useCallback(async () => {
    if (!!messageId && !reanswering) {
      setReanswering(true);
      try {
        await dataService.reAnswer(messageId);
        dispatch[match ? "chat" : "document"].version();
      } catch (err) {
        feedback(err.data);
      } finally {
        setReanswering(false);
      }
    }
  }, [reanswering, match]);

  const deleteHandler = useCallback(async () => {
    if (messageId) {
      try {
        await dataService.removeAnswer(messageId);
        dispatch[match ? "chat" : "document"].version();
        message.success("删除成功！");
      } catch (err) {
        feedback(err.data);
      }
    }
  }, [match]);

  const checkingHandler = useCallback(() => {
    const checkable = setBubbleBgColor(messageId, true);
    if (!checkable) return;
    dispatch.user.update({
      checking: !checking,
      checkedList: checking ? [] : [messageId],
    });
    disableComposer();
    setBubbleSelectable(false);
  }, [checking]);

  const playHandler = useCallback(async () => {
    if (loading) {
      audio.pause();
      setLoading(false);
    } else {
      setLoading(true);
      try {
        const res = await axios({
          method: "get",
          url: `/api/system/text2audio/?text=${text}`,
          responseType: "arraybuffer",
        });
        const mp3Url = window.URL.createObjectURL(new Blob([res.data]));
        audio.src = mp3Url;
        audio.play();
      } catch (err) {
        feedback("播放错误");
      }
    }
  }, [setLoading, loading, text, audio]);

  const exportHandler = useCallback(() => {
    try {
      const date = Date.now();
      let iframe = document.createElement("iframe");
      iframe.src = `https://chat.phitrellis.com/api/data/${messageId}/download_docx/`;
      iframe.style.display = "none";
      iframe.id = `iframe-${date}`;
      document.body.appendChild(iframe);
      setTimeout(() => {
        document.body.removeChild(iframe);
      }, 1000);
    } catch (err) {
      feedback(err.data || "内容导出失败！");
    }
  }, [messageId]);

  const menuList = [
    {
      title: copied ? "复制成功" : "复制",
      handler: () => copyHandler(text),
      icon: copied ? <CheckOutlined style={{ color: "var(--status-success)" }} /> : <CopyOutlined />,
    },
    {
      title: writed ? "写入成功" : "写入输入框",
      handler: writeHandler,
      icon: writed ? <CheckOutlined style={{ color: "var(--status-success)" }} /> : <EditOutlined />,
    },
    {
      title: format ? "原始格式展示" : "MarkDown形式展示",
      handler: formatHandler,
      icon: format ? <FileTextOutlined /> : <FileMarkdownOutlined />,
    },
    {
      title: "重新回答",
      handler: reanswerHandler,
      icon: reanswering ? <LoadingOutlined /> : <RedoOutlined />,
    },
    {
      title: "删除",
      handler: () => setPopOpen(true),
      icon: <DeleteOutlined />,
    },
    {
      title: "批量删除",
      handler: checkingHandler,
      icon: <OrderedListOutlined />,
    },
    {
      title: "导出word",
      handler: exportHandler,
      icon: <CloudDownloadOutlined />,
    },
  ];

  return (
    <div
      className={cls("BubbleToolbar", position === "left" ? styles.leftBubbleToolbar : styles.rightBubbleToolbar)}
      style={{ zIndex: 1 }}
    >
      <div className="BubbleToolbar-btns">
        {menuList
          .filter((item) => (position === "left" ? item : item.title !== "删除"))
          .filter((item) => (position === "left" && isLast ? item : item.title !== "重新回答"))
          .filter((item) => (position === "left" && status === 2 ? item : item.title !== "导出word"))
          // 论文检索
          .filter((item) => (isScholar ? scholarTools.includes(item.title) : item))
          .filter((item) => (isMusic ? scholarTools.includes(item.title) : item))
          .filter((item) => (isImage ? scholarTools.includes(item.title) : item))
          .map(({ handler, icon, title }, index) => {
            return (
              <Tooltip
                key={`toolbar-item-${index}`}
                title={title}
                overlayInnerStyle={{ userSelect: "none" }}
                getPopupContainer={(triggerNode) => triggerNode}
              >
                <Popconfirm
                  //给删除按钮加popconfirm
                  open={title === "删除" && popOpen}
                  onCancel={() => {
                    setPopOpen(false);
                  }}
                  onOpenChange={(open) => {
                    if (!open) setPopOpen(false);
                  }}
                  onConfirm={deleteHandler}
                  title="确定要删除该条消息吗？"
                  arrowPointAtCenter
                >
                  <Space
                    onClick={handler}
                    className="menu-item"
                  >
                    {icon}
                  </Space>
                </Popconfirm>
              </Tooltip>
            );
          })}
      </div>
      <div className="BubbleToolbar-exts">
        <span className="time">{createdAt}</span>
      </div>
    </div>
  );
}

export default memo(BubbleToolbar);
