import "katex/dist/katex.min.css";
import "@/themes/index.less";
import NormalMessage from "./NormalMessage";
import ResolveMessage from "./ResolveMessage";

function MessageContentRender({ msg, setText, onMessageTyping }) {
  let { content, type, position } = msg;

  if (position === "left" && content?.message_type?.includes("scholar")) {
    try {
      JSON.parse(content.text);
    } catch (error) {
      content = { ...content, message_type: "text" };
    }
  }

  // if (position === "left" && content?.question_type === "论文撰写" && content?.status === 5) {
  //   content = { ...content, message_type: "outline" };
  // }

  switch (type) {
    case "normal-message":
      return (
        <NormalMessage
          messageType={content.message_type}
          text={content?.text}
          createdAt={content?.createdAt}
          setComposerText={setText}
          messageId={content?.messageId}
          position={position}
          isLast={content?.isLast}
          questionType={content?.question_type}
          placeholderType={content?.placeholder_type}
          status={content?.status}
          isContent={content?.isContent}
          config={content?.config}
          onMessageTyping={onMessageTyping}
        />
      );
    case "resolve-message":
      return (
        <ResolveMessage
          setComposerText={setText}
          messageId={content?.messageId}
        />
      );
    default:
      return null;
  }
}

export default MessageContentRender;
