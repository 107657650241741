export const config = {
  state: {
    pubmedTypes: [],
    pubSearchStrategy: "",
    db: "综合库",
    analysisType: "数据分析",
    language: "中文",
    knowledge: null,
  },
  reducers: {
    update(state, payload) {
      return {
        ...state,
        ...payload,
      };
    },
    resetConfig(state, payload) {
      return {
        pubmedTypes: [],
        pubSearchStrategy: "",
        db: "综合库",
        analysisType: "数据分析",
        language: "中文",
        knowledge: null,
      };
    },
  },
};
