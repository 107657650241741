const addRow = (
  <svg
    className="addRow"
    viewBox="0 0 1024 1024"
    xmlns="http://www.w3.org/2000/svg"
    width="16px"
    height="16px"
  >
    <path
      d="M810.666667 448v128h128v85.333333h-128v128h-85.333334v-128h-128v-85.333333h128v-128h85.333334z m-469.333334 213.333333v85.333334H85.333333v-85.333334h256z m256-213.333333v85.333333H85.333333v-85.333333h512z m256-213.333333v85.333333H85.333333v-85.333333h768z"
      fill="currentColor"
    ></path>
  </svg>
);

const addLeaf = (
  <svg
    className="addLeaf"
    viewBox="0 0 1024 1024"
    xmlns="http://www.w3.org/2000/svg"
    width="14px"
    height="16px"
  >
    <path
      d="M980.4 546.1c10.9-11.4 10.9-29.9 0-41.3l-26.3-27.5c-10.9-11.4-28.5-11.4-39.4 0l-32.8 34.5 65.7 68.8 32.8-34.5zM935.3 594.9L869.7 526 691.3 711.8l65.5 68.8zM743.5 793.5l-65-68.3-39.9 111.9z"
      fill="currentColor"
    ></path>
    <path
      d="M982 848.5c-19.3 0-35 15.7-35 35v40.9H379V653.7h191c19.3 0 35-15.7 35-35s-15.7-35-35-35H344c-19.3 0-35 15.7-35 35V754H189V370.3h427.8c32 0 58-26 58-58V87.5c0-32-25.9-58-58-58H65c-32 0-58 26-58 58v224.8c0 32 26 58 58 58h54V785c0 15.3 9.8 28.3 23.5 33 5.6 3.8 12.3 6 19.5 6h147v135.4c0 19.3 15.7 35 35 35h638c19.3 0 35-15.7 35-35v-75.9c0-19.3-15.7-35-35-35zM77 99.5h527.8v200.8H77V99.5z"
      fill="currentColor"
    ></path>
  </svg>
);

export const EditorIcons = ({ type }) => {
  const IconTypes = { addLeaf, addRow };
  return IconTypes[type];
};
