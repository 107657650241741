import { Modal, Input, Form, Row, Col } from "antd";
import { useState } from "react";
import { formatText } from "./index.js";
import "./style.less";

const RefineModal = ({ open, setOpen, onOk }) => {
  const [form] = Form.useForm();
  const [refineText, setRefineText] = useState("");
  const [similarText, setSimilarText] = useState("");
  return (
    <Modal
      title="高级降重"
      wrapClassName="refineModal"
      width={700}
      open={open}
      destroyOnClose={true}
      onCancel={() => {
        setOpen(false);
        form.resetFields();
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            const text = formatText(refineText, similarText);
            onOk(text);
            setOpen(false);
            form.resetFields();
          })
          .catch((errorInfo) => {
            console.log(errorInfo);
          });
      }}
    >
      <Form
        style={{ width: "100%" }}
        form={form}
      >
        <Row gutter={6}>
          <Col span={12}>
            <Form.Item
              name="refineText"
              rules={[
                {
                  required: true,
                  message: "请输入降重文本!",
                },
              ]}
            >
              <Input.TextArea
                placeholder="请输入降重文本"
                allowClear={true}
                style={{ height: "200px" }}
                autoSize={{ minRows: 2, maxRows: 8 }}
                onChange={(e) => {
                  setRefineText(e.target.value);
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="similarText"
              rules={[
                {
                  required: true,
                  message: "请输入相似文本!",
                },
              ]}
            >
              <Input.TextArea
                placeholder="请输入相似文本"
                allowClear={true}
                style={{ height: "200px" }}
                autoSize={{ minRows: 2, maxRows: 8 }}
                onChange={(e) => {
                  setSimilarText(e.target.value);
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default RefineModal;
