import TextBubbleAction from "./TextBubbleAction";
import style from "./TextBubbleAction.module.less";

function BubbleAction({ children, messageId, status, setComposerText, actionType, actionText, actionList, text }) {
  switch (actionType) {
    case "text":
    case "outline":
      return (
        <TextBubbleAction
          messageId={messageId}
          text={text}
          status={status}
          setComposerText={setComposerText}
          actionText={actionText}
          actionList={actionList}
        >
          {children}
        </TextBubbleAction>
      );
    default:
      return <div className={style.textBubbleActionWrapper}>{children}</div>;
  }
}

export default BubbleAction;
