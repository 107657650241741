import { debounce } from "lodash-es";
import { useState, useLayoutEffect, useEffect } from "react";
import cls from "classnames";
import styles from "./index.module.less";
import { useSelector } from "react-redux";

function ThesisComposerPlaceholder() {
  const [height, setHeight] = useState(0);
  const { dark } = useSelector((state) => state.theme);

  const updateHeight = debounce(() => {
    const heightRef = document.querySelector(".message-scrollbar");
    if (!heightRef) {
      setHeight(0);
      return;
    }
    setHeight(heightRef.getBoundingClientRect().height);
  }, 250);

  useLayoutEffect(() => {
    updateHeight();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", updateHeight);

    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  return (
    <div
      className={cls(styles.placeHolderContainer, "placeHolderContainer")}
      style={{ height: `${height}px` }}
    >
      <video
        className=""
        autoPlay={true}
        playsInline={true}
        loop={true}
        muted={true}
        src={
          dark
            ? "https://file-1302065078.cos.ap-beijing.myqcloud.com/media/paper_guide_dark.mp4"
            : "https://file-1302065078.cos.ap-beijing.myqcloud.com/media/paper_guide.mp4"
        }
      />
    </div>
  );
}

export default ThesisComposerPlaceholder;
