export const gift = (
  <svg
    viewBox="0 0 1024 1024"
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
  >
    <path
      d="M466.864016 850.990802h90.416867V421.510683h-90.416867zM753.473893 225.317674a101.429178 101.429178 0 0 0-144.898826 1.593887 112.876185 112.876185 0 0 0-192.42564 0 101.429178 101.429178 0 1 0-73.318806 172.139805H681.02448a101.429178 101.429178 0 0 0 72.449413-173.878591z m-411.077968 128.380359a56.510542 56.510542 0 1 1 23.183812-107.37003 56.945238 56.945238 0 0 1 18.691948 13.62049L463.676242 353.698033z m105.051648-89.402575a67.957549 67.957549 0 0 1 128.815056 0l-64.479977 76.796377z m273.85878 72.449413A56.220744 56.220744 0 0 1 681.02448 353.698033h-120.555823l79.114759-94.474034a57.95953 57.95953 0 0 1 18.836847-13.62049 56.510542 56.510542 0 0 1 63.175888 91.576058z"
      fill="#D84D32"
      p-id="1622"
    ></path>
    <path
      d="M805.782369 557.280883v271.105703a22.604217 22.604217 0 0 1-22.604217 22.604216H579.595302V557.280883z m-362.247064 293.854818h-202.858356a22.604217 22.604217 0 0 1-22.604217-22.604217V557.280883h226.042168v293.709919zM218.217631 421.510683h226.042168v113.021084h-226.042168a22.604217 22.604217 0 0 1-22.604217-22.604216v-67.667752a22.749116 22.749116 0 0 1 22.604217-22.604217z m362.247064 0h226.042168a22.749116 22.749116 0 0 1 22.604217 22.604217v67.812651a22.604217 22.604217 0 0 1-22.604217 22.604216H579.595302z"
      fill="#3CA3A9"
      p-id="1623"
    ></path>
  </svg>
);
