import cls from "classnames";
import EditorInput from "../EditorInput";
import styles from "./index.module.less";

const EditorHeader = ({ title, error = [], lang, updateText }) => {
  return (
    <div className={cls(styles.editorHeader, "editorHeader")}>
      <EditorInput
        tagType="h3"
        label="标题："
        placeholder="请输入文章标题"
        value={title}
        required={true}
        invalid={error.includes("title")}
        onChange={({ text }) => updateText({ text, type: "title" })}
      />
      <EditorInput
        tagType="p"
        label="语言："
        placeholder="请输入撰写论文需要使用的语言，例如 English、Chinese 等，支持小语种"
        value={lang}
        invalid={error.includes("lang")}
        required={true}
        onChange={({ text }) => updateText({ text, type: "lang" })}
      />
    </div>
  );
};

export default EditorHeader;
