function getConfigKey(quickReply) {
  switch (quickReply) {
    case "灵知":
      return {
        pubmedTypes: [],
        pubSearchStrategy: "",
      };
    case "论文撰写":
    case "文献综述":
    case "开题报告":
      return {
        db: "",
      };
    case "数据分析":
      return {
        analysisType: "",
      };
    case "翻译":
      return {
        language: "",
      };
    case "学术问答":
      return {
        db: "",
        knowledge: null,
      };
    default:
      return null;
  }
}

export function newConfigKey(quickReply) {
  return getConfigKey(quickReply);
}
