import { useCallback } from "react";
import { Button, Tooltip, Popconfirm } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import EditorInput from "../EditorInput";
import EditorDataSelector from "../EditorDataSelector";
import { EditorIcons } from "./EditorIcons";
import cls from "classnames";
import styles from "./index.module.less";

const EditorParagraph = ({
  children,
  error = [],
  dataAllowed = false,
  dataOptions = [],
  level1,
  level2,
  level3,
  childSize,
  nodeName,
  nodeDesc,
  nodeExpt,
  nodeData,
  deleteDisabled,
  updateText,
  addNode,
  addChildNode,
  deleteNode,
}) => {
  const isLevel2 = !!level2 || level2 === 0;
  const isLevel3 = !!level3 || level3 === 0;
  const tagType = isLevel3 ? "h6" : isLevel2 ? "h5" : "h4";
  const typeName = [isLevel3 ? "三级" : isLevel2 ? "二级" : "一级", "章节"].join("");
  const nextTypeName = [isLevel2 ? "三级" : "二级", "章节"].join("");
  const typeNo = `${level1 + 1}${level2 + 1 ? `.${level2 + 1}` : ""}${level3 + 1 ? `.${level3 + 1}` : ""}`;

  return (
    <div className={cls(styles.editorParagraph, "editorParagraph", tagType)}>
      <div className="editorParagraphContent">
        <div className="editorParagraphAction">
          <Tooltip title={`下方插入${typeName}`}>
            <Button
              type="default"
              key={`下方插入${typeName}`}
              size="small"
              onClick={(e) =>
                isLevel3
                  ? addNode({
                      level1Index: level1,
                      level2Index: level2,
                      level3Index: level3 !== undefined ? level3 : childSize - 1 || 0,
                    })
                  : isLevel2
                    ? addNode({
                        level1Index: level1,
                        level2Index: level2 !== undefined ? level2 : childSize - 1 || 0,
                      })
                    : addNode({ level1Index: level1 })
              }
            >
              <span className="anticon anticon-delete">
                <EditorIcons type="addRow" />
              </span>
            </Button>
          </Tooltip>
          {isLevel3 ? null : (
            <Tooltip title={`新增${nextTypeName}`}>
              <Button
                type="default"
                key={`新增${nextTypeName}${level1}-${level2}-${level3}`}
                size="small"
                onClick={(e) =>
                  isLevel2
                    ? addChildNode({ level1Index: level1, level2Index: level2, level3Index: childSize - 1 || 0 })
                    : addChildNode({ level1Index: level1, level2Index: childSize - 1 || 0 })
                }
              >
                <span className="anticon anticon-delete">
                  <EditorIcons type="addLeaf" />
                </span>
              </Button>
            </Tooltip>
          )}
          <Popconfirm
            title={`确认删除【${typeName}${typeNo}】？`}
            onConfirm={() => deleteNode({ level1Index: level1, level2Index: level2, level3Index: level3 })}
            cancelText="取消"
            okText="删除"
          >
            <Tooltip title={deleteDisabled ? `至少需要一个章节，不能删除` : `删除${typeName}`}>
              <Button
                type="default"
                key={`删除${typeName}`}
                size="small"
                disabled={deleteDisabled}
              >
                <DeleteOutlined />
              </Button>
            </Tooltip>
          </Popconfirm>
        </div>
        <EditorInput
          invalid={error.includes(
            isLevel3
              ? ["name", level1, level2, level3].join("-")
              : isLevel2
                ? ["name", level1, level2].join("-")
                : ["name", level1].join("-")
          )}
          tagType={tagType}
          required={true}
          label={`章节${typeNo}`}
          placeholder="请输入章节名称，例如：社会关系的意义 或 The meaning of social relations"
          value={nodeName}
          onChange={({ text }) =>
            updateText({ text, type: "nodeName", level1Index: level1, level2Index: level2, level3Index: level3 })
          }
        />
        <EditorInput
          tagType="p"
          label="简介："
          required={false}
          placeholder="请输入章节简介内容（请勿在内容中使用换行）"
          value={nodeDesc}
          onChange={({ text }) =>
            updateText({ text, type: "nodeDesc", level1Index: level1, level2Index: level2, level3Index: level3 })
          }
        />
        <EditorInput
          tagType="p"
          label="要求："
          required={false}
          placeholder="请输入章节要求，例如：大约500字"
          value={nodeExpt}
          onChange={({ text }) =>
            updateText({ text, type: "nodeExpt", level1Index: level1, level2Index: level2, level3Index: level3 })
          }
        />
        {!dataAllowed ? null : (
          <EditorDataSelector
            label="数据："
            required={false}
            placeholder="请选择当前目录内撰写此章节需要用到的数据（不多于3个）"
            dataOptions={dataOptions}
            value={nodeData}
            onChange={({ data }) =>
              updateText({
                text: data,
                type: "nodeData",
                level1Index: level1,
                level2Index: level2,
                level3Index: level3,
              })
            }
          />
        )}
      </div>
      {children}
    </div>
  );
};

export default EditorParagraph;
