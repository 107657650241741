import { Modal, Button } from "antd";
import { useLayoutEffect, useRef } from "react";
import useScreenSize from "@/hooks/useScreenSize";
import "./index.less";

const EditorModal = ({ show, children, onOk, onCancel, onReset }) => {
  const { middle } = useScreenSize();

  useLayoutEffect(() => {
    if (!show) {
      return;
    }
    setTimeout(() => {
      const modal = document.querySelector(".editorModal");
      if (!modal) {
        return;
      }
      const input = modal.querySelector('h3[contenteditable="plaintext-only"]');
      if (!input) {
        return;
      }
      input.focus();
    }, 500);
  }, [show]);

  return (
    <Modal
      title="大纲编辑"
      wrapClassName="editorModal"
      width={middle ? "60vw" : "100vw"}
      height={middle ? "75vh" : ""}
      style={middle ? { maxWidth: "1000px" } : { top: "0px" }}
      styles={middle ? { height: "75vh" } : { top: 0, height: "var(--doc-height)", "padding-bottom": "0px" }}
      maskClosable={false}
      keyboard={false}
      closeIcon={null}
      open={show}
      onOk={onOk}
      onCancel={onCancel}
      destroyOnClose={true}
      footer={[
        <Button
          key="reset"
          onClick={onReset}
        >
          清空
        </Button>,
        <Button
          key="cancel"
          onClick={onCancel}
        >
          放弃编辑
        </Button>,
        <Button
          key="confirm"
          type="primary"
          onClick={onOk}
        >
          发送
        </Button>,
      ]}
    >
      <div style={{ overflow: "hidden auto", height: `calc(var(--doc-height) - ${middle ? 300 : 135}px)` }}>
        {children}
      </div>
    </Modal>
  );
};

export default EditorModal;
