import { useCallback, useState } from "react";
import useScreenSize from "@/hooks/useScreenSize";
import { Button, Tooltip } from "antd";
import { useMatch } from "react-router-dom";
import { FormOutlined } from "@ant-design/icons";
import RefineModal from "./RefineModal";

function RefineButton({ quickReply, onSend, initialValue }) {
  const { middle } = useScreenSize();
  const matchAtChat = useMatch("/chat");
  const [open, setOpen] = useState(false);
  const [openTooltip, setOpenTooltip] = useState(false);
  const openHandler = useCallback(() => {
    setOpen(true);
  }, []);

  return (
    <Tooltip
      placement="top"
      title={"输入原始文本和与之重复文本，GPT自动对比后降重"}
      open={openTooltip}
    >
      {middle ? (
        <Button
          shape="round"
          size="small"
          className="input-tools-btn"
          onClick={openHandler}
          onMouseEnter={() => setOpenTooltip(true)}
          onMouseLeave={() => setOpenTooltip(false)}
        >
          高级降重
        </Button>
      ) : (
        <Button
          size="large"
          type="text"
          icon={<FormOutlined style={{ color: "var(--brand-1)" }} />}
          onClick={openHandler}
          onMouseEnter={() => setOpenTooltip(true)}
          onMouseLeave={() => setOpenTooltip(false)}
        ></Button>
      )}
      <RefineModal
        open={open}
        setOpen={setOpen}
        onOk={onSend}
      />
    </Tooltip>
  );
}

export default RefineButton;
