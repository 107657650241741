import { useCallback, useEffect } from "react";
import { Select, Tooltip } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useMatch } from "react-router-dom";
import styles from "../index.module.less";

function DBSelect({ quickReply }) {
  const dispatch = useDispatch();
  const { db, knowledge } = useSelector((state) => state.config);
  const matchAtChat = useMatch("/chat");
  const options = [
    {
      label: "综合库",
      value: "综合库",
      tip: "Semantic综合论文库",
    },
    {
      label: "知网",
      value: "知网",
      tip: "CNKI-中国知网，耗时较长",
    },
    {
      label: "PubMed",
      value: "PubMed",
      tip: "PubMed医学论文库",
    },
    {
      label: "全库",
      value: "全库",
      tip: "3种论文库全部检索，耗时较长",
    },
  ];
  return (
    <div className={styles.selectorContainer}>
      <span className="selector-label">网络文献</span>
      <Select
        size="small"
        bordered={false}
        value={db}
        onChange={(value) => {
          dispatch.config.update({
            db: value,
          });
        }}
        style={{ width: "90px" }}
        disabled={knowledge !== null}
      >
        {options.map((item) => (
          <Select.Option
            key={item.value}
            value={item.value}
          >
            <Tooltip
              title={item.tip}
              placement="top"
            >
              {item.label}
            </Tooltip>
          </Select.Option>
        ))}
      </Select>
    </div>
  );
}

export default DBSelect;
