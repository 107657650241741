import { cloneDeep } from "lodash-es";

export const OUTLINE_EMPTY = {
  title: "",
  lang: "",
  id: Date.now(),

  nodes: [
    {
      nodeName: "",
      nodeDesc: "",
      nodeExpt: "",
      nodeData: [],
      nodes: [
        {
          nodeName: "",
          nodeDesc: "",
          nodeExpt: "",
          nodeData: [],
          nodes: [
            {
              nodeName: "",
              nodeDesc: "",
              nodeExpt: "",
              nodeData: [],
            },
          ],
        },
      ],
    },
  ],
};

export const parseOutline = (data) => {
  const outline = cloneDeep(OUTLINE_EMPTY);
  outline.title = data.name || "";
  outline.lang = data.language || "";
  outline.id = Date.now();

  const nodes = (data.outline || []).map((node) => {
    const childNodes = (node.subchapters || []).map((childNode) => {
      const grandChildNodes = (childNode.subchapters || []).map((grandChildNode) => {
        return {
          nodeName: `${grandChildNode.title || ""}${
            grandChildNode.title_en ? ["(", grandChildNode.title_en, ")"].join("") : ""
          }`,
          nodeDesc: grandChildNode.summary || "",
          nodeExpt: grandChildNode.requirement,
          nodeData: grandChildNode.data,
        };
      });
      return {
        nodeName: `${childNode.title || ""}${childNode.title_en ? ["(", childNode.title_en, ")"].join("") : ""}`,
        nodeDesc: childNode.summary || "",
        nodeExpt: childNode.requirement,
        nodeData: childNode.data,
        nodes: grandChildNodes || [],
      };
    });
    return {
      nodeName: `${node.title || ""}${node.title_en ? ["(", node.title_en, ")"].join("") : ""}`,
      nodeDesc: node.summary || "",
      nodeExpt: node.requirement,
      nodeData: node.data,
      nodes: childNodes || [],
    };
  });
  if (nodes.length) {
    outline.nodes = nodes;
  }
  return outline;
};

export const validateOutline = (outline) => {
  const errors = [];
  if (!outline || !outline.title) {
    errors.push("title");
  }
  if (!outline.lang) {
    errors.push("lang");
  }
  for (let index = 0; index < outline.nodes.length; index++) {
    const item = outline.nodes[index];
    if (!item.nodeName) {
      errors.push(`name-${index}`);
    }
    if (!item.nodes || !item.nodes.length) {
      continue;
    }
    for (let index1 = 0; index1 < item.nodes.length; index1++) {
      const child = item.nodes[index1];
      if (!child.nodeName) {
        errors.push(`name-${index}-${index1}`);
      }
      if (!child.nodes || !child.nodes.length) {
        continue;
      }

      for (let index2 = 0; index2 < child.nodes.length; index2++) {
        const grandChild = child.nodes[index2];
        if (!grandChild.nodeName) {
          errors.push(`name-${index}-${index1}-${index2}`);
        }
      }
    }
  }
  return errors;
};

export const formatOutline = (outline) => {
  const content = outline.nodes.map((item, index) => {
    const childContent = (item.nodes || []).map((childNode, childIndex) => {
      const grandChildContent = (childNode.nodes || []).map((grandChildNode, grandChildIndex) => {
        return [
          `${index + 1}.${childIndex + 1}.${grandChildIndex + 1} ${grandChildNode.nodeName}`,
          `简介： ${grandChildNode.nodeDesc}`,
          `要求： ${grandChildNode.nodeExpt}`,
          grandChildNode.nodeData?.length
            ? `数据： ${(grandChildNode.nodeData.filter((x) => !!x) || []).join(",")}`
            : "",
          ``,
        ].join("\n");
      });
      return [
        `${index + 1}.${childIndex + 1} ${childNode.nodeName}`,
        `简介： ${childNode.nodeDesc}`,
        `要求： ${childNode.nodeExpt}`,
        childNode.nodeData?.length ? `数据： ${(childNode.nodeData.filter((x) => !!x) || []).join(",")}` : "",
        ``,
        grandChildContent.join("\n"),
      ].join("\n");
    });
    return [
      `${index + 1} ${item.nodeName}`,
      `简介：${item.nodeDesc}`,
      `要求：${item.nodeExpt}`,
      item.nodeData?.length ? `数据： ${(item.nodeData.filter((x) => !!x) || []).join(",")}` : "",
      ``,
      childContent.join("\n"),
    ].join("\n");
  });
  return (
    `` +
    `题目：${outline.title}
语言：${outline.lang}

${content.join("\n")}
`
  );
};
