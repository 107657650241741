import { useCallback, useState, useEffect } from "react";
import { Button, Tooltip } from "antd";
import { AlignLeftOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { useMatch } from "react-router-dom";
import useScreenSize from "@/hooks/useScreenSize";
import OutlineEditor from "./index";
import OutlineIcons from "@/icons/OutlineIcons";

function OutlineButton({ quickReply, onSend, initialValue, setText }) {
  const { middle } = useScreenSize();
  const [show, setShow] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const { sessionType } = useSelector((state) => state.document);
  const { quickReplyMode, editOutlineData } = useSelector((state) => state.chat);
  const matchAtChat = useMatch("/chat");
  const matchAtDocument = useMatch("/document");

  const outlineHandler = useCallback(() => {
    setShow(true);
    setTooltipOpen(false);
  }, [show]);

  useEffect(() => {
    if (editOutlineData) {
      setShow(true);
      setText(editOutlineData);
    }
  }, [editOutlineData]);

  const component = (
    <Tooltip
      placement="top"
      title={"创建或编辑标准格式的大纲"}
      open={tooltipOpen}
    >
      {middle ? (
        <Button
          shape="round"
          size="small"
          className="input-tools-btn"
          onClick={outlineHandler}
          onMouseEnter={() => setTooltipOpen(true)}
          onMouseLeave={() => setTooltipOpen(false)}
        >
          大纲编辑
        </Button>
      ) : (
        <Button
          size="large"
          type="text"
          icon={
            <OutlineIcons
              type="outline"
              style={{ color: "var(--brand-1)" }}
            />
          }
          onClick={outlineHandler}
          onMouseEnter={() => setTooltipOpen(true)}
          onMouseLeave={() => setTooltipOpen(false)}
        />
      )}
      <OutlineEditor
        show={show}
        setShow={setShow}
        onSend={onSend}
        initialValue={initialValue}
      />
    </Tooltip>
  );

  return component;

  // if (matchAtDocument && sessionType === 2 && !["普通问答", "逐一问答"].includes(quickReplyMode)) {
  //   return component;
  // }

  // if (matchAtChat) {
  //   switch (quickReply) {
  //     case "文献综述":
  //     case "论文撰写":
  //     case "开题报告":
  //     case "长文撰写":
  //     case "文章重组":
  //     case "文章优化":
  //     case "文章配图":
  //       return component;
  //   }
  // }
}

export default OutlineButton;
